<template>
  <subview class="newcompetition-appearance-default">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left"/>

            {{ $t('pages.newcompetition.appearance.title') }}
          </div>
        </template>
        <template #last>
          <div class="current-step"> {{ $t('pages.newcompetition.step') }} 3/6</div>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <AppearanceLink v-for="type in types" :key="type" :type="type"/>
      <!-- <router-link class="appearance-link-component" :to="{ name: 'newcompetition.appearance.gender' }">
        <span class="name"> Gender </span>

        <div class="value">
          <p class="text">{{ selectedGender || this.$t('pages.newcompetition.appearance.none') }}</p>
        </div>

        <Icon :size="20" type="caret-right"/>
      </router-link> -->

      <div class='new-competition__balances'>
        <div class='new-competition__balances-item'>
          <p class='new-competition__balances-item-header'>Your balance</p>
          <svg-icon size="size16x16" name="icoCoin" />
          <span>{{balance}}</span>
          <button @click='goToStore'>
            <Icon :size="14" type="add-circle"/>
          </button>
        </div>
        <div class='new-competition__balances-item'>
          <p class='new-competition__balances-item-header'>Total on Venue</p>
          <svg-icon size="size16x16" name="icoCoin" />
          <span>{{addonsPrice}}</span>
        </div>
      </div>

    </view-content>

    <Popup :open='isShowNotEnoughPopup' @close='isShowNotEnoughPopup = false'>
      <template v-slot:default>
        <div class='new-competition__notenoght'>
          <h2>Not enough stars!</h2>
          <p>You need <Icon :size="14" type="star"/> {{addonsPrice - balance}} to apply all addons</p>
        </div>
      </template>
      <template v-slot:actions>
        <a-button class='new-competition__button-star' @click='goToStore'>
          <Icon :size="14" type="star-border"/>
          <span>Shop Now</span>
        </a-button>
        <a-button class='secondary' @click='isShowNotEnoughPopup = false'>
          <span>Later</span>
        </a-button>
      </template>
    </Popup>

    <view-footer>
      <a-button :disabled='isFetching' @click="next">
        {{ $t('pages.newcompetition.next') }}

        <Icon :size="20" type="arrow-right" />
      </a-button>
    </view-footer>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import AppearanceLink from '@/components/NewCompetition/Appearance/AppearanceLink'
import Popup from '@/components/Popup'
import newcompetition from '@/mixins/newcompetition'
import {mapGetters, mapActions} from 'vuex'
import services from '@/api/services';

export default {
  name: 'Appearance',
  components: {
    AppearanceLink,
    Icon,
    Popup
  },
  mixins: [
    newcompetition
  ],
  data() {
    return {
      isShowNotEnoughPopup: false,
      isFetching: false,
    }
  },
  computed: {
    types () {
      return Object.keys(this.selectedAppearance)
    },
    ...mapGetters({
      'addonsPrice': 'newcompetition/addonsPrice',
      'balance': 'balance'
    })
  },
  methods: {
    next () {

      if (this.addonsPrice===0) {
        this.$router.push({ name: 'newcompetition.competitors'});
        return;
      }

      this.isFetching = true
      services.checkEnoughBalanceToCreateCompetition(this.addonsPrice).then(response => {
        if (response.data.result) {
          this.$router.push({ name: 'newcompetition.competitors'})
        } else {
          this.isShowNotEnoughPopup = true
        }
      }).finally(() => {
        this.isFetching = false
      });
    },
    goToStore() {
      this.saveState();
      this.$router.push({name: "coins-storage", query: {back: this.$route.path}})
    },
    ...mapActions({
      'saveState': 'newcompetition/saveState'
    })
  }
}
</script>

<style scoped>

</style>
