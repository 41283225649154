<template>
  <router-link class="appearance-link-component" :to="route">
    <span class="name"> {{ name }} </span>

    <div class="value">
      <div class="image" v-if="value.image">
        <img :src="value.image" alt="">
      </div>

      <p class="text"> {{ value.text }} </p>
    </div>

    <Icon :size="20" type="caret-right"/>
  </router-link>
</template>

<script>
import Icon from '@/components/Icon'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'AppearanceLink',
  mixins: [
    newcompetition
  ],
  components: {
    Icon
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    name () {
      return this.selectedSport.appearance[this.type]?.name
    },
    value () {
      const { selectedAppearance } = this

      switch (this.type) {
        case 'venue':
          return {
            image: selectedAppearance.venue?.image_url,
            text: selectedAppearance.venue?.name || this.$t('pages.newcompetition.appearance.none')
          }

        case 'addons':

          if (this.$store.getters['newcompetition/selectedAddons'].length === 0) {
            return {
              text: this.$t('pages.newcompetition.appearance.none')
            }
          }
          return {
            text: this.$store.getters['newcompetition/selectedAddons'].map(item => item.name).join(', ')
          }

        case 'sponsors':
          return {
            text: selectedAppearance.sponsors?.name || this.$t('pages.newcompetition.appearance.none')
          }

        case 'judgements':
          return {
            text: selectedAppearance.judgements?.name || this.$t('pages.newcompetition.appearance.none')
          }

        case 'description':
          return {
            text: selectedAppearance.description || this.$t('pages.newcompetition.appearance.none')
          }

        default:
          return {
            text: this.$t('pages.newcompetition.appearance.none')
          }
      }
    },
    route () {
      return { name: 'newcompetition.appearance.' + this.type }
    }
  }
}
</script>
